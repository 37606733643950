<template>
	<div class="adomany">
		<div class="promoTevekenysegHeader">
			<div class="grid">
				<div class="col-4 md:col-4 lg:col-4 lead leadHeader tevekenyseg">Tevékenységünk</div>
			</div>
			<div class="grid">
				<div class="col-6 md:col-6 lg:col-4 lead"><div class="leadText">A Váci Egyházmegyei Karitász 1991-es megalapítása óta gondoskodik a legrászorultabb emberekről</div></div>
			</div>
		</div>

		<div class="grid justify-content-center">
			<div class="col-12 md:col-10 lg:col-8 contentText tevekenysegContent">
				<div class="adomanyTitle">
					A Váci Egyházmegyei Karitász 1991 megalapítása óta gondoskodik a legrászorultabb emberekről, melyet a váci megyéspüspöktől kapott rendszeres támogatásból, a hívek, magánszemélyek és vállalkozások adakozásából és egy kis adománybolt bevételéből finanszíroz.
				</div>
				<div class="tevekenysegBorder">
					<p class="tevekenysegInfo">A váci központ az egyházmegye 114 plébániai karitászcsoportjai önkénteseivel együtt Pest megye keleti, Heves, Jász-Nagykun-Szolnok  és Bács-Kiskun megye egyes részei, valamint a szegénységgel leginkább sújtott Nógrád megye teljes területén végzik munkájukat. Karitatív tevékenységünket állami támogatás nélkül, az egyházmegye támogatása mellett egyedül adományozóink pénzbeli és tárgyi adományai, és a több száz önkéntes elkötelezett munkája teszi lehetővé.</p>
					<p class="tevekenysegInfo">Szolgálatunkat a  Magyar Katolikus Egyház hivatalos segélyszervezete - a Katolikus Karitász  a keretén belül elsősorban az alábbi területeken végezzük:</p>

					<table class="adomanyDescripionTitle">
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								Váci Egyházmegye területén élő rászorulók lehetőségekhez mérten való támogatása - személyre szabott segítségnyújtással
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">					
								Az adományok rászorulókhoz való eljuttatása a karitászcsoportok és egyéb segítőink közreműködésével.
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">					
								A  karitászcsoportok önkénteseinek koordinálása; szakmai és lelki vezetése, valamint egyedi esetekben a karitászcsoportok anyagi támogatása a rászorulókért végzett hatékonyabb segítségnyújtásuk érdekében.
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">					
								Részvétel a katasztrófák és természeti csapások, humanitárius katasztrófák kárelhárításában, illetve kárenyhítésében. 
							</td>
						</tr>
					</table>
					<div class="tevekenysegInfoRed">
						A jelenleg kialakult ukrajnai háborús helyzetben is igyekszünk mindent megtenni a kilátástalan helyzetbe került, menekülni kényszerülő emberekért.
					</div>
				</div>
			</div>	
		</div>
		<div class="grid">
			<div class="col-12 md:col-12 lg:col-12 adomanyFooter">
				<AppFooter />
			</div>
		</div>

    </div>
</template>

<script>
import AppFooter from '../AppFooter.vue';

export default {
    components: {
        'AppFooter': AppFooter,
    },
	mounted() {
		this.$appState.myCart = true;
	},	
}
</script>

<style lang="scss" scoped>

</style>
